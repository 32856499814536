leadership animation
.LeadershipAnimBox {
  pointer-events: none;
}
.LeadershipAnimBox .LeadershipLeftBox {
  width: 0;
}
.LeadershipAnimBox .LeadershipLeftBox svg {
  width: 0;
  transition: 0.4s;
}
.LeadershipAnimBox .LeadershipRightBox {
  width: 0;
  padding: 30px;
}
.LeadershipAnimBox .LeadershipRightBox p {
  display: none;
  padding: 0;
}
.LeadershipAnimBox .LeadershipRightBox::before {
  left: 52px;
}

/* defines the animation */
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.card-list-wrp .MuiGrid-item > div {
  opacity: 1;

  /* -webkit-animation: fadeInUp 1s ease-in-out 0s forwards;
  animation: fadeInUp 1s ease-in-out 0s forwards; */
}
.card-list-wrp .MuiGrid-item .card-1 {
  /* -webkit-animation-delay: 1s;
  animation-delay: 1s; */
}
.card-list-wrp .MuiGrid-item .card-2 {
  /* -webkit-animation-delay: 3s;
  animation-delay: 3s; */
}
.card-list-wrp .MuiGrid-item .card-3 {
  /* -webkit-animation-delay: 5s;
  animation-delay: 5s; */
}
.card-list-wrp .MuiGrid-item .card-4 {
  /* -webkit-animation-delay: 7s;
  animation-delay: 7s; */
}
.card-list-wrp .MuiGrid-item .card-5 {
  /* -webkit-animation-delay: 9s;
  animation-delay: 9s; */
}
.card-list-wrp .MuiGrid-item .card-6 {
  /* -webkit-animation-delay: 11s;
  animation-delay: 11s; */
}
