/* Fonts Faces Definition */
@font-face {
  font-family: "DIN Pro";
  src: url("assets/fonts/DINPro/DIN Pro Condensed Black 900.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "DIN Pro";
  src: url("assets/fonts/DINPro/DIN Pro Condensed Bold 700.otf")
    format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Courier New";
  src: url("assets/fonts/CourierNew/CourierStd-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Courier New";
  src: url("assets/fonts/CourierNew/CourierStd.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Nexa Bold";
  src: url("assets/fonts/NexaBold/Nexa Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Nexa Bold";
  src: url("assets/fonts/NexaBold/Nexa Light.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
/* CSS Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Nexa Bold", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
